<template>
  <div id="subjects_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ $t("subjects.title") }}</h1>
      <div class="bb-mobile-header-right"></div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="bb-subjects-table-search d-flex align-items-center">
        <i class="flaticon-search"></i>
        <input
          type="text"
          :placeholder="$t('subjects.searchInput.placeholder')"
          v-model="searchTerm"
        />
      </div>
      <button class="bb-new-subject-btn" @click="createSubject">
        {{ $t("subjects.createSubjectBtn") }}
      </button>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="allSubjects"
      :search="searchTerm"
      hide-default-footer
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      @page-count="pagination.pageCount = $event"
      @pagination="updatePaginationText($event)"
      ref="subjectsTable"
      class="bb-table subjects-table"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left" @click="showSubject(item.id)">
            <div class="bb-subject-badge">
              {{ item.type == 'Culpability' ? 
                $t('subjectFormModal.type.options.culpability') : 
                $t('subjectFormModal.type.options.litigation') }}
            </div>
          </td>
          <td class="text-left" @click="showSubject(item.id)">
            {{ item.subjectNumber }}
          </td>
          <td class="text-left" @click="showSubject(item.id)">
            {{ item.courtNumber }}
          </td>
          <td class="text-left" @click="showSubject(item.id)">
            {{ item.courtName }}
          </td>
          <td class="text-left" @click="showSubject(item.id)">
            {{ item.client ? item.client.first_name + ' ' + item.client.last_name : 'Unknown'}}
          </td>
          
          <td class="text-right">
            <BBDropdown positionClass="right">
              <template v-slot:toggle>
                <i class="ki ki-more-ver"></i>
              </template>
              <template v-slot:items>
                <div class="action-item">
                  <router-link
                    :to="{ name: 'subject', params: { id: item.id } }"
                  >
                    <i class="far fa-user"></i>
                    <span>{{ $t("subjects.subjectDropdown.showBtn") }}</span>
                  </router-link>
                </div>
                <div class="action-item" @click="editSubject(item)">
                  <i class="flaticon2-gear"></i>
                  <span>{{ $t("subjects.subjectDropdown.editBtn") }}</span>
                </div>
                <div class="action-item danger" @click="deleteSubject(item)">
                  <i class="far fa-trash-alt"></i>
                  <span>{{ $t("subjects.subjectDropdown.deleteBtn") }}</span>
                </div>
              </template>
            </BBDropdown>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="bb-table-footer subjects-table-footer">
      <v-pagination
        class="bb-pagination"
        v-model="pagination.page"
        :length="pagination.pageCount"
      ></v-pagination>
      <div class="d-flex justufy-content-between align-items-center">
        <BBSelect
          class="bb-pagination-per-page-select"
          :options="pagination.perPageOptions"
          v-model="pagination.itemsPerPage"
        />
        <span class="bb-table-pagination-text">
          {{ $t("subjects.table.pagination.show") }}
          {{ pagination.currentFromItem }} - {{ pagination.currentToItem }}
          {{ $t("subjects.table.pagination.of") }}
          {{ pagination.currentTotalItems }}
        </span>
      </div>
    </div>
    <div id="mobile_subjects_wrapper">
      <div class="bb-input-group search-input">
        <i class="fas fa-search"></i>
        <input
          type="text"
          v-model="searchTerm"
          :placeholder="$t('subjects.mobileSearchInput.placeholder')"
        />
        <button class="bb-badge badge-rounded" @click="searchTerm = ''">
          <i class="ki ki-close icon-md"></i>
        </button>
      </div>
      <div class="subjects-list">
        <div
          class="subject-item"
          v-for="subject in displaySubjects"
          :key="subject.id"
          @click="showSubject(subject.id)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <div class="bb-subject-badge">
              {{ subject.initials }}
            </div>
            <div class="subject-details">
              <span>{{ subject.subjectNumber }}</span>
              <small>{{ subject.courtNumber }}</small>
              <small>{{ subject.courtName }}</small>
            </div>
          </div>
          <i class="la la-angle-right"></i>
        </div>
      </div>
      <button class="mobile-new-subject-btn" @click="createSubject">
        <i class="la la-plus"></i>
      </button>
    </div>
    <SubjectFormModal ref="subjectFormModal" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_SUBJECTS } from "@/core/services/store/subjects.module";
import { mapGetters } from "vuex";
import { EventBus } from "@/core/services/event-bus.js";
import BBDropdown from "@/view/content/BBDropdown.vue";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import SubjectFormModal from "@/view/layout/extras/modal/SubjectFormModal.vue";
import subjectFormModalMixin from "@/core/mixins/subjectFormModalMixin.js";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";

export default {
  name: "Subjects",
  components: { BBDropdown, BBSelect, SubjectFormModal },
  mixins: [subjectFormModalMixin, mobileAsideMixin],
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        {
          text: this.$t("subjects.table.columns.type"),
          value: "type",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("subjects.table.columns.subjectNumber"),
          value: "subjectNumber",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("subjects.table.columns.courtNumber"),
          value: "courtNumber",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("subjects.table.columns.courtName"),
          value: "courtName",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("subjects.table.columns.clientName"),
          value: "client",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          align: "right",
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        perPageOptions: [
          { text: "10", value: 10 },
          { text: "20", value: 20 },
          { text: "50", value: 50 },
        ],
        perPageDropdownIsOpen: false,
        currentFromItem: 0,
        currentToItem: 0,
        currentTotalItems: 0,
      },
    };
  },
  methods: {
    /**
     * Update pagination text based on tables pagination event
     * @param {Object} event
     * @return {void}
     */
    updatePaginationText(event) {
      this.pagination.currentFromItem = parseInt(event.pageStart) + 1;
      this.pagination.currentToItem = event.pageStop;
      this.pagination.currentTotalItems = event.itemsLength;
    },

    /**
     * Set value for items per page in table
     * @param {Number} perPage
     * @return {void}
     */
    setPerPageItems(perPage) {
      this.pagination.itemsPerPage = perPage;
    },

    /**
     * Delete the subject
     * @param {Object} subject
     * @return {void}
     */
    deleteSubject(subject) {
      EventBus.$emit("deletingSubject", {
        subject: subject,
        successCallback: function () {
          this.$store.dispatch(GET_SUBJECTS);
        },
      });
    },

    /**
     * Handle table row click
     * @param {Number} subjectId
     * @return {void}
     */
    showSubject(subjectId) {
      this.$router.push({ name: "subject", params: { id: subjectId } });
    },
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("subjects.title") }]);
    // Dispatch store GET_SUBJECTS action to get subjects from api
    this.$store.dispatch(GET_SUBJECTS);
  },
  computed: {
    ...mapGetters(["allSubjects"]),

    /**
     * Return array of subjects to display
     * @return {Array}
     */
    displaySubjects() {
      if (this.allSubjects === 0) {
        return [];
      }

      return this.allSubjects.filter((subject) =>
        subject.courtNumber.includes(this.searchTerm.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/subjects.scss";
</style>
